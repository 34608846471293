.hiding{
  background-color: #ff3f34;
  color: white;
  padding: 100px 0;
  text-align: center;
}

.food-icon{
  width: 5rem;
}

.resultHeader{
  background-color: #ff3f34;
  color: white;
  padding: 10px 0;
  text-align: center;
}

.result-header-food-icon{
  width: 3rem
}

.test{
  border-style: solid;
  border-radius: 80%;
}